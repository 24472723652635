import { createStore } from 'redux';
import { getTastamatInfo, handleError } from "../services/locker.service";

const url = window.location.pathname.split('/');
const orderCode = url[url.length - 1];

const initialState = {
  locker: {},
  rating: null,
  orderCode: orderCode,
  type: "new",
  parcelInfo: {}
};

export const getInfo = async () => {
  await getTastamatInfo(orderCode)
    .then(
      async res => {
        await store.dispatch({ type: 'GET_INFO', payload: res.data });
        const endOfIndex = res.data.locker && res.data.locker.index.slice(-2);
        if (parseInt(endOfIndex) < 31)
          store.dispatch({ type: 'SET_TYPE', payload: 'old' });
        else
          store.dispatch({ type: 'SET_TYPE', payload: 'new' });
      },
      error => handleError(error, orderCode)
    )
};

function tastamatInfo(state = initialState, action) {
  switch (action.type) {
    case 'GET_INFO':
      return { ...state, ...action.payload };
    case 'SET_TYPE':
      return { ...state, type: action.payload };
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    case 'SET_PARCEL_INFO':
      return { ...state, parcelInfo: action.payload };
    case 'LOADING':
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state
  }
}

let store = createStore(tastamatInfo);

// store.subscribe(() => console.log(store.getState()));

if (url.length > 2) {
  getInfo();
}

export default store;
