import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './style.scss';

class LoadingSpinner extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.style.display = 'contents';
  }

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }
  render() {
    return ReactDOM.createPortal(
      <div className="loader"></div>,
      this.el,
    )
  }
}

export default LoadingSpinner;
