import axios from 'axios';
import store from './store';

const axiosInterceptorsConfig = () => {
  axios.interceptors.request.use(config => {
    store.dispatch({ type: 'LOADING', payload: true });
    return config
  }, error => {
    return Promise.reject(error);
  });

  axios.interceptors.response.use(response => {
    store.dispatch({ type: 'LOADING', payload: false });
    return response;
  }, error => {
    store.dispatch({ type: 'LOADING', payload: false });
    return Promise.reject(error);
  });
};

export default axiosInterceptorsConfig;